import React, { useContext, useRef, useState, useEffect } from 'react'
import moment from 'moment'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import Editor from '../../../components/admin/editor'
import AdminLayout from '../../../components/layouts/admin'
import { getBlogPostById, saveBlogPost } from '../../../utils/requests'
import { LoadingContext } from '../../../context/loading-context'
import { Input, TextArea, Label } from '../../../components/common/input'
import {
  AdminTitle,
  AdminAuthor,
  AdminPostedAtDate,
} from '../../../components/common/typography'
import {
  AdminDashboardButtonWrapper,
  AdminDashboardActionButton,
} from '../../../components/common/button'

const Wrapper = styled.div`
  display: grid;
  row-gap: 1.5rem;
  margin: 0 auto;
  padding: 60px 30% 0px 30%;
  height:100vh;
  overflow-y:auto;
`

const LegacyContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
`

/**
 * @todo Create Lambda function for the Update Post
 */
const EditBlogPost = ({ params }) => {
  const [isLegacy, setIsLegacy] = useState(false)
  const { loading, setLoading } = useContext(LoadingContext)
  const [state, setState] = useState({
    title: '',
    description: '',
    author: '',
    date: null,
    topic: '',
    data: {},
  })
  const editorRef = useRef(null)

  const handleSave = async (e) => {
    try {
      e.preventDefault()
      setLoading(true)
      const { awsSlug } = params
      const editorData = await editorRef.current.save()
      const response = await saveBlogPost({
        ...state,
        id: awsSlug,
        data: editorData,
      })
      setLoading(false)

      if (response.success) {
        navigate(`/admin/blog/${response.data.body.id}`)
      }
    } catch (err) {
      console.error(err.message)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prev) => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    const { awsSlug } = params
    const fetchPost = async () => {
      setLoading(true)
      try {
        const response = await getBlogPostById(awsSlug)

        if (typeof response.data.Item.data === 'string') {
          setIsLegacy(true)
        } else {
          if (response.success) {
            setState(response.data.Item)
          }
        }
      } catch (err) {
        console.log(err.message)
      }
      setLoading(false)
    }
    fetchPost()
  }, [params, setLoading])

  return (
    <AdminLayout>
      {isLegacy ? (
        <LegacyContainer>
          <div>
            This Post was built with Markdown and is not supported right now
          </div>
        </LegacyContainer>
      ) : (
        <form onSubmit={handleSave}>
          <AdminDashboardButtonWrapper>
            <AdminDashboardActionButton onClick={handleSave}>
              Update
            </AdminDashboardActionButton>
          </AdminDashboardButtonWrapper>
          <Wrapper>
            <div>
              <AdminTitle>{state.title}</AdminTitle>
              <AdminAuthor>{state.author}</AdminAuthor>
              <AdminPostedAtDate>
                {moment(state.date).format('MMMM Do, YYYY')}
              </AdminPostedAtDate>
            </div>
            <Label>
              Title
              <TextArea
                name="title"
                onBlur={handleChange}
                defaultValue={state.title}
                required
              />
            </Label>
            <Label>
              Description
              <TextArea
                name="description"
                onBlur={handleChange}
                defaultValue={state.description}
                required
              />
            </Label>
            <Label>
              Topic
              <Input
                name="topic"
                onBlur={handleChange}
                defaultValue={state.topic}
                required
              />
            </Label>
            <div>
              <Label>Content</Label>
              {!loading && 'data' in state && (
                <Editor editorRef={editorRef} data={state.data} required />
              )}
            </div>
          </Wrapper>
        </form>
      )}
    </AdminLayout>
  )
}

export default EditBlogPost
